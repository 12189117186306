import { ROUTES } from '@constants/routes';
import { getSidebarIcons, SidebarIcon } from '@mock-data/sidebar-icon';
import React from 'react';

type NavigationLink = {
  link: string;
  icon: React.ReactNode;
  name: string;
};
export const navigationLinks: NavigationLink[] = [
  {
    link: ROUTES.allProfiles,
    name: 'Учётные записи',
    icon: getSidebarIcons(SidebarIcon.AllProfiles),
  },
  {
    link: `${ROUTES.profile}/1`,
    name: 'Запись',
    icon: getSidebarIcons(SidebarIcon.Profile),
  },
  {
    link: `${ROUTES.profile}/create`,
    name: 'Создать запись',
    icon: getSidebarIcons(SidebarIcon.Profile),
  },
  {
    link: ROUTES.eduOrganizations,
    name: 'Образовательные организации',
    icon: getSidebarIcons(SidebarIcon.EduOrganizations),
  },
  {
    link: ROUTES.settings,
    name: 'Настройки',
    icon: getSidebarIcons(SidebarIcon.Settings),
  },
  {
    link: ROUTES.settings,
    name: 'Конфигурация',
    icon: getSidebarIcons(SidebarIcon.Configurations),
  },
];
