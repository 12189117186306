export const ROUTES = {
  main: '/',
  login: '/login',
  allProfiles: '/profiles',
  profile: '/profile',
  profileItem: '/profile/:userId',
  profileCreate: '/profile/create',
  eduOrganizations: '/eduOrganizations',
  eduOrganization: 'eduOrganizations/view/:id',
  settings: 'settings',
  configurations: 'configurations',
};
