import axios from 'axios';
import qs from 'query-string';

import { BASE_API_URL } from '@constants/config';

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'none' }),
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);
