import { Box } from '@mui/material';
import LoginForm from '@components/login-form/login-form';

const LoginPage = () => {
  return (
    <Box
      sx={{
        margin: 'auto',
        minHeight: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoginForm />
    </Box>
  );
};

export default LoginPage;
