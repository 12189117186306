import { Box } from '@mui/material';

type Props = {
  index: number;
  currentTab: number;
  children: React.ReactNode;
};

const ProfileGeneralContent = ({ index, currentTab, children }: Props) => {
  return (
    <Box
      hidden={index !== currentTab}
      sx={{
        '& .MuiGrid-root:last-child': {
          borderBottom: 'none',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default ProfileGeneralContent;
