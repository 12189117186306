import { IconTrash } from '@components/icons';
import InfoExpandableChunk from '@components/info-expandable-chunk/info-expandable-chunk';
import { InfoField } from '@components/info-field/info-field';
import { ContactPoint, ContactType } from '@declarations/user';
import { Box, Grid, IconButton } from '@mui/material';

type Props = {
  userContacts: ContactPoint[];
  isEdit?: boolean;
  handleRemove: (id: number) => void;
  handleChange: (id: number, value: ContactPoint) => void;
};

const Contacts = ({
  userContacts,
  isEdit,
  handleRemove,
  handleChange,
}: Props) => {
  const title = 'Контактные данные';
  const contactOptions: ContactType[] = [
    'TELEPHONE',
    'FAX',
    'EMAIL',
    'PAGER',
    'URL',
    'SMS',
    'OTHER',
  ];
  const handleRemoveContact = (id: number) => () => {
    handleRemove(id);
  };
  const handleContactChange =
    (id: number) =>
    ({
      fieldId,
      value,
    }: {
      fieldId: string | number;
      value: string | number;
    }) => {
      const contact = userContacts[id];

      contact[fieldId as 'type' | 'contact' | 'using' | 'priority'] =
        value as ContactType;

      handleChange(id, contact);
    };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {userContacts?.map((el, index) => (
        <InfoExpandableChunk
          title={title}
          index={index + 1}
          key={el.id}
          isEdit={isEdit}
          deleteButton={
            <IconButton
              sx={{ padding: '0 9px 0 0', color: 'error.main' }}
              onClick={handleRemoveContact(index)}
            >
              <IconTrash />
            </IconButton>
          }
        >
          <Grid sx={{ paddingBottom: '24px' }} container columnSpacing={3}>
            <InfoField
              fieldId="type"
              title="Тип контакта"
              content={el.type}
              options={contactOptions}
              xs={6}
              isEdit={isEdit}
              handleChange={handleContactChange(index)}
            />
            <InfoField
              fieldId="contact"
              title="Контакт"
              content={el.contact}
              xs={6}
              isEdit={isEdit}
              handleChange={handleContactChange(index)}
            />
          </Grid>
          <Grid sx={{ paddingTop: '24px' }} container columnSpacing={3}>
            <InfoField
              fieldId="using"
              title="Тип использования"
              content={el.using || '-'}
              xs={6}
              isEdit={isEdit}
              handleChange={handleContactChange(index)}
            />
            <InfoField
              fieldId="priority"
              title="Приоритет контакта"
              content={el.priority || '-'}
              xs={6}
              isEdit={isEdit}
              handleChange={handleContactChange(index)}
            />
          </Grid>
        </InfoExpandableChunk>
      ))}
    </Box>
  );
};

export default Contacts;
