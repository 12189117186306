import InfoExpandableChunk from '@components/info-expandable-chunk/info-expandable-chunk';
import { AccountIdentifier, AccountIdentifierType } from '@declarations/user';
import { Box, Grid, IconButton } from '@mui/material';
import { InfoField } from '@components/info-field/info-field';
import { IconTrash } from '@components/icons';

type Props = {
  userIdentifiers: AccountIdentifier[];
  isEdit?: boolean;
  handleRemove: (id: number) => void;
  handleChange: (id: number, value: AccountIdentifier) => void;
};

const Identifiers = ({
  userIdentifiers,
  isEdit,
  handleRemove,
  handleChange,
}: Props) => {
  const title = 'Идентификатор';

  const handleRemoveIdentifier = (id: number) => () => {
    handleRemove(id);
  };

  const handleIdentifierChange =
    (id: number) =>
    ({
      fieldId,
      value,
    }: {
      fieldId: string | number;
      value: string | number;
    }) => {
      const identifier = userIdentifiers[id];

      identifier[fieldId as 'type' | 'identifier'] =
        value as AccountIdentifierType;

      handleChange(id, identifier);
    };

  const identifierOptions: AccountIdentifierType[] = [
    'SNILS',
    'OGRNIP',
    'BIRTH_CERTIFICATE',
    'FOREIGN_PASSPORT',
    'PASSPORT',
    'BIRTH_CERTIFICATE_FOREIGN',
  ];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
      {userIdentifiers?.map((el, index) => (
        <InfoExpandableChunk
          title={title}
          index={index + 1}
          key={el.id}
          isEdit={isEdit}
          deleteButton={
            <IconButton
              sx={{ padding: '0 9px 0 0', color: 'error.main' }}
              onClick={handleRemoveIdentifier(index)}
            >
              <IconTrash />
            </IconButton>
          }
        >
          <Grid container spacing={3}>
            <InfoField
              title="Тип идентификатора"
              fieldId="type"
              content={el.type}
              xs={6}
              options={identifierOptions}
              isEdit={isEdit}
              handleChange={handleIdentifierChange(index)}
            />
            <InfoField
              title="Значение идентификатора"
              content={el.identifier}
              fieldId="identifier"
              xs={6}
              isEdit={isEdit}
              handleChange={handleIdentifierChange(index)}
            />
          </Grid>
        </InfoExpandableChunk>
      ))}
    </Box>
  );
};

export default Identifiers;
