import { Button, Typography } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useLocation,
} from 'react-router-dom';

type NavProps = RouterLinkProps & {
  isActive?: boolean;
  shrinked?: boolean;
  icon?: React.ReactNode;
  reloadDocument?: boolean;
};

const NavLink = ({ children, to, icon, reloadDocument }: NavProps) => {
  const location = useLocation();
  const match = location.pathname === to;
  return (
    <Button
      variant={match ? 'contained' : 'text'}
      fullWidth
      component={RouterLink}
      to={to}
      startIcon={icon}
      reloadDocument={reloadDocument}
      sx={{
        justifyContent: 'flex-start',
        boxShadow: 'none',
        opacity: 0.85,
      }}
    >
      <Typography variant="caption">{children}</Typography>
    </Button>
  );
};

export default NavLink;
