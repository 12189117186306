import {
  Box,
  Checkbox,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { StyledTableCell } from '@components/common/table/table-cell/table-cell';
import { visuallyHidden } from '@mui/utils';
import { IconSortAsc, IconSortDesc } from '@components/icons';

export interface HeadCell<DataType> {
  disablePadding: boolean;
  id: keyof Omit<DataType, symbol>;
  label: string;
  numeric: boolean;
}

interface CspTableHeadProps<DataType> {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DataType
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headCells: HeadCell<DataType>[];
}

export default function CspTableHead<DataType>(
  props: CspTableHeadProps<DataType>
) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;
  const createSortHandler =
    (property: keyof DataType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead
      sx={{
        backgroundColor: 'background.default',
        '& th:first-child': {
          borderBottomLeftRadius: '12px',
        },
        '& th:last-child': {
          borderBottomRightRadius: '12px',
        },
        '& th': {
          borderBottom: 'none',
        },
      }}
    >
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={order === 'asc' ? IconSortAsc : IconSortDesc}
              sx={{
                color: orderBy === headCell.id ? 'grey.700' : 'grey.600',
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
