import React from 'react';
import { Button } from '@mui/material';
import { Outlet } from 'react-router-dom';

export const EduOrganizationList = () => {
  return (
    <div>
      <h1>Образовательные организации</h1>
      <Button variant="contained">Новая запись</Button>
      <Button variant="contained">Импорт</Button>
      <Outlet />
    </div>
  );
};
