import {
  Grid,
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';

type InfoFieldProps = {
  title: string;
  content: string | number;
  fieldId?: string;
  xs?: number;
  isEdit?: boolean;
  required?: boolean;
  isError?: boolean;
  options?: string[] | number[];
  handleChange?: ({
    value,
    fieldId,
  }: {
    value: string | number;
    fieldId: string;
  }) => void;
};

export const InfoField = ({
  title,
  content,
  fieldId = 'default',
  xs = 4,
  isEdit,
  required,
  isError,
  options,
  handleChange,
}: InfoFieldProps) => {
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = { value: e.target.value, fieldId };
    handleChange && handleChange(result);
  };
  const handleSelectChange = (e: SelectChangeEvent<string | number>) => {
    const result = { value: e.target.value, fieldId };
    handleChange && handleChange(result);
  };
  return (
    <Grid item xs={xs}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        <Box>
          <Typography variant="captionInput" color="grey.600">
            {title}
          </Typography>
          {required && (
            <Typography variant="captionInput" color="error.main">
              &nbsp;*
            </Typography>
          )}
        </Box>
        {isEdit ? (
          options?.length ? (
            <FormControl fullWidth>
              <Select
                id="simple-select"
                value={content}
                onChange={handleSelectChange}
                name={fieldId}
                error={isError}
              >
                {options.map((el) => (
                  <MenuItem value={el} key={el}>
                    {el}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              value={content}
              onChange={handleTextFieldChange}
              error={isError}
              placeholder="-"
            />
          )
        ) : (
          <Typography>{content}</Typography>
        )}
      </Box>
    </Grid>
  );
};
