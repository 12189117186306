import { UserTypeGetResponse, UserTypeEditRequest } from '@declarations/user';
import { endPoints } from '@api/end-points';
import { axiosInstance } from './instances';

export const userApi = {
  getUser: (id: number) => {
    return axiosInstance.get<UserTypeGetResponse>(
      `${endPoints.userInfo}/${id}`
    );
  },
  saveUser: (user: UserTypeEditRequest) => {
    return axiosInstance.post<UserTypeEditRequest>(
      `${endPoints.userInfo}`,
      user
    );
  },
};
