import { Routes, Route } from 'react-router-dom';

import MainPage from '@pages/main-page/main-page';
import ProfilesPage from '@pages/profiles-page/profiles-page';
import ProfilePage from '@pages/profile-page/profile-page';
import LoginPage from '@pages/login-page/login-page';
import { EduOrganizationList } from '@pages/educational-organizations/list';
import { ROUTES as RouteList } from './constants/routes';

export default function App() {
  return (
    <Routes>
      <Route path={RouteList.main} element={<MainPage />}>
        <Route path={RouteList.allProfiles} element={<ProfilesPage />} />
        <Route path={RouteList.profileItem} element={<ProfilePage />} />
        <Route path={RouteList.profileCreate} element={<ProfilePage />} />
        <Route
          path={RouteList.eduOrganizations}
          element={<EduOrganizationList />}
        />
      </Route>
      <Route path={RouteList.login} element={<LoginPage />} />
    </Routes>
  );
}
