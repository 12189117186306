import useInitialErrors from '@hooks/formik-initial-errors';
import {
  Button,
  TextField,
  Typography,
  Paper,
  Box,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';

import { useState } from 'react';
import { object as objectYup, string as stringYup } from 'yup';
import { IconEyeClosedThin, IconEyeOpenThin } from '@components/icons';

export const initialLoginData = {
  login: '',
  password: '',
};

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const initialErrors = useInitialErrors(
    initialLoginData,
    getValidationSchema()
  );

  const onSubmit = (
    data: typeof initialLoginData,
    formikHelpers: FormikHelpers<typeof initialLoginData>
  ) => {
    formikHelpers.setSubmitting(false);

    // eslint-disable-next-line no-console
    console.log('что-то делаем...');
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      onSubmit={onSubmit}
      initialErrors={initialErrors}
      initialValues={initialLoginData}
      validationSchema={getValidationSchema}
    >
      {({ isValid, errors, touched }: FormikProps<typeof initialLoginData>) => {
        return (
          <Form>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '442px',
                padding: '56px 48px',
                borderRadius: '16px',
              }}
            >
              <Box
                sx={{ display: 'flex', justifyContent: 'center', pb: '32px' }}
              >
                <Typography variant="h3">Sign in to your account</Typography>
              </Box>

              <Field
                name="login"
                as={TextField}
                label="Имя пользователя или E-mail"
                placeholder="exampleemail@example.mail"
                fullWidth
                error={touched.login && !!errors.login}
                helperText={touched.login && errors.login}
              />

              <Field
                name="password"
                as={TextField}
                type={showPassword ? 'text' : 'password'}
                label="Пароль"
                variant="outlined"
                fullWidth
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Показать пароль"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="small"
                        edge="end"
                      >
                        {showPassword ? (
                          <IconEyeClosedThin width={16} height={16} />
                        ) : (
                          <IconEyeOpenThin width={16} height={16} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button type="submit" disabled={!isValid} size="large">
                Вход
              </Button>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="caption" sx={{ color: 'grey.600' }}>
                  Or sign in with
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" size="small">
                  ESIA (CryptoPRO)
                </Button>
              </Box>
            </Paper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;

const getValidationSchema = () =>
  objectYup().shape({
    login: stringYup()
      .nullable()
      .required('Введите имя пользователя или e-mail'),
    password: stringYup().nullable().min(6, '').required('Введите пароль'),
  });
