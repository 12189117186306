import { IconChevronDown } from '@components/icons';
import { Box, Collapse, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';

type InfoBlockType = {
  title: string;
  children: React.ReactNode;
  addButton?: React.ReactNode;
  expandable?: boolean;
  isExpanded?: boolean;
};

type Props = {
  title: string;
  expanded: boolean;
  onClick: () => void;
};

const ExpandableTitle = ({ title, expanded, onClick }: Props) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          transform: expanded ? 'rotate(0deg)' : 'rotate(-90deg)',
          transition: 'transform 0.15s',
        }}
      >
        <IconChevronDown />
      </Box>
      <Typography>{title}</Typography>
    </Box>
  );
};

export const InfoBlock = ({
  title,
  children,
  addButton,
  expandable = false,
  isExpanded = false,
}: InfoBlockType) => {
  const [expanded, setExpanded] = useState(isExpanded);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid
      container
      columnSpacing={3}
      sx={{
        padding: '24px 20px',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.400',
      }}
    >
      <Grid item xs={3}>
        {expandable ? (
          <ExpandableTitle
            title={title}
            expanded={expanded}
            onClick={handleExpanded}
          />
        ) : (
          title
        )}
      </Grid>
      <Grid item lg={9} xl={6} display="flex" flexDirection="column" gap="16px">
        <Box>{addButton}</Box>
        <Box>
          {expandable ? (
            <Collapse in={expanded} timeout={0}>
              {children}
            </Collapse>
          ) : (
            children
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
