import { IconEdit } from '@components/icons';
import { Box, Button, Tab, TabProps, Tabs, styled } from '@mui/material';

type Props = {
  currentTab: number;
  handleTabChange: (newValue: number) => void;
  handleEditButtonClick: () => void;
};

const StyledTab = styled(Tab)<TabProps>(({ theme }) => ({
  padding: 0,
  textTransform: 'none',
  minHeight: 0,
  ...theme.typography.h4,
  '&.Mui-selected': {
    color: theme.palette.grey[800],
  },
}));

const ProfilePageHeader = ({
  currentTab,
  handleTabChange,
  handleEditButtonClick,
}: Props) => {
  const onChange = (event: React.SyntheticEvent, newValue: number) => {
    handleTabChange(newValue);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid',
        borderBottomColor: 'grey.400',
      }}
    >
      <Tabs
        sx={{
          pl: '20px',
          minHeight: '44px',
          '& .MuiTabs-scroller': {
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiTabs-flexContainer': {
            gap: '20px',
          },
        }}
        value={currentTab}
        onChange={onChange}
      >
        <StyledTab label="Учётная запись" disableRipple />
      </Tabs>
      <Button
        sx={{ minWidth: '0', padding: '9px', width: '36px', height: '36px' }}
        onClick={handleEditButtonClick}
      >
        <IconEdit />
      </Button>
    </Box>
  );
};

export default ProfilePageHeader;
