import { UserType } from '@declarations/user';
import { Action, UserActions } from '../types/general-Info-actions';

export const generalInfoActions: UserActions = {
  changeGeneralInfoField: 'generalInfo/changeGeneralInfoField',
  addIdentificator: 'generalInfo/addIdentificator',
  removeIdentificator: 'generalInfo/removeIdentificator',
  changeIdentificator: 'generalInfo/changeIdentificator',
  addContact: 'generalInfo/addContact',
  removeContact: 'generalInfo/removeContact',
  changeContact: 'generalInfo/changeContact',
  setUser: 'generalInfo/setUser',
};

export function generalInfoReducer(state: UserType, action: Action) {
  switch (action.type) {
    case generalInfoActions.changeGeneralInfoField: {
      return {
        ...state,
        [action.payload.field]: action.payload.newValue,
      };
    }
    case generalInfoActions.addIdentificator: {
      const identifiers = [...state.accountIdentifierDTO];
      identifiers.push(action.payload.newValue);
      return {
        ...state,
        accountIdentifierDTO: identifiers,
      };
    }
    case generalInfoActions.removeIdentificator: {
      const identifiers = [...state.accountIdentifierDTO];
      identifiers.splice(action.payload.id, 1);
      return {
        ...state,
        accountIdentifierDTO: identifiers,
      };
    }
    case generalInfoActions.changeIdentificator: {
      const identifiers = [...state.accountIdentifierDTO];
      identifiers[action.payload.id] = action.payload.newValue;
      return {
        ...state,
        accountIdentifierDTO: identifiers,
      };
    }
    case generalInfoActions.addContact: {
      const contacts = [...state.contactPointDTO];
      contacts.push(action.payload.newValue);
      return {
        ...state,
        contactPointDTO: contacts,
      };
    }
    case generalInfoActions.removeContact: {
      const contacts = [...state.contactPointDTO];
      contacts.splice(action.payload.id, 1);
      return {
        ...state,
        contactPointDTO: contacts,
      };
    }
    case generalInfoActions.changeContact: {
      const contacts = [...state.contactPointDTO];
      contacts[action.payload.id] = action.payload.newValue;
      return {
        ...state,
        contactPointDTO: contacts,
      };
    }
    case generalInfoActions.setUser: {
      return action.payload;
    }
    default:
      return state;
  }
}
