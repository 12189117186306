import { Box, Button, Paper } from '@mui/material';
import { Reducer, useEffect, useReducer, useState } from 'react';
import { AccountIdentifier, ContactPoint, UserType } from '@declarations/user';
import { userApi } from '@api/user';
import { v4 as uuidv4 } from 'uuid';
import { InfoBlock } from '@components/info-block/info-block';
import { useLocation, useParams } from 'react-router-dom';
import { IconPlus } from '@components/icons';
import GeneralInfo from './general-Info';
import Identifiers from './Identifiers';
import Contacts from './contacts';
import ProfileGeneralContent from './profile-general-content';
import ProfilePageHeader from './profile-page-header';
import { Action } from './types/general-Info-actions';
import {
  generalInfoReducer,
  generalInfoActions,
} from './reducers/general-Info-reducer';

const ProfilePage = () => {
  const location = useLocation();
  const params = useParams<{ userId: string }>();

  const createMode = location.pathname.includes('/create');
  const userId = parseInt(params.userId as string, 10) || '';
  const [user, setUser] = useState<UserType>(getDefaultUser());
  const [currentUser, dispatchCurrentUser] = useReducer<
    Reducer<UserType, Action>
  >(generalInfoReducer, getDefaultUser());

  const [editableUser, setEditableUser] = useState<UserType>(getDefaultUser());
  const [currentTab, setTab] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(createMode);

  function getDefaultUser(): UserType {
    return {
      id: 0,
      nameGiven: '',
      nameFamily: '',
      nameMiddle: '',
      esiaTypeDTO: '',
      accountStatusDTO: '',
      accountIdentifierDTO: [],
      contactPointDTO: [],
      structuralRole: '',
    };
  }
  const loadUser = async (id: number) => {
    const response = await userApi.getUser(id);
    const data = response.data as UserType;
    data.accountIdentifierDTO.map((el) => {
      el.id = uuidv4();
      return el;
    });
    data.contactPointDTO.map((el) => {
      el.id = uuidv4();
      return el;
    });
    setEditableUser(data);
    dispatchCurrentUser({ type: generalInfoActions.setUser, payload: data });
    setUser(data);
  };
  useEffect(() => {
    if (userId) {
      loadUser(userId);
    }
  }, [userId]);

  useEffect(() => {
    if (createMode) {
      setEditableUser(getDefaultUser());
      setUser(getDefaultUser());
      dispatchCurrentUser({
        type: generalInfoActions.setUser,
        payload: getDefaultUser(),
      });
    }
  }, [createMode]);

  const handleTabChange = (newValue: number) => {
    setTab(newValue);
  };

  useEffect(() => {
    setEditableUser(currentUser);
  }, [currentUser]);

  const handleEditInfoChange = ({
    value,
    fieldId,
  }: {
    value: string | number;
    fieldId: string;
  }) => {
    dispatchCurrentUser({
      type: generalInfoActions.changeGeneralInfoField,
      payload: { field: fieldId, newValue: value },
    });
  };

  const handleEditButtonClick = () => {
    if (!createMode) {
      setIsEdit(!isEdit);
      isEdit
        ? dispatchCurrentUser({
            type: generalInfoActions.setUser,
            payload: editableUser,
          })
        : dispatchCurrentUser({
            type: generalInfoActions.setUser,
            payload: user,
          });
    }
  };

  const handleRemoveIdentifier = (id: number) => {
    dispatchCurrentUser({
      type: generalInfoActions.removeIdentificator,
      payload: { id },
    });
  };

  const handleAddIdentifier = () => {
    dispatchCurrentUser({
      type: generalInfoActions.addIdentificator,
      payload: { newValue: { id: uuidv4(), type: '', identifier: '' } },
    });
  };
  const handleChangeIdentifier = (
    fieldId: number,
    value: AccountIdentifier
  ) => {
    dispatchCurrentUser({
      type: generalInfoActions.changeIdentificator,
      payload: { id: fieldId, newValue: value },
    });
  };
  const handleRemoveContact = (id: number) => {
    dispatchCurrentUser({
      type: generalInfoActions.removeContact,
      payload: { id },
    });
  };

  const handleAddContact = () => {
    dispatchCurrentUser({
      type: generalInfoActions.addContact,
      payload: { newValue: { id: uuidv4(), type: '', contact: '' } },
    });
  };
  const handleChangeContact = (fieldId: number, value: ContactPoint) => {
    dispatchCurrentUser({
      type: generalInfoActions.changeContact,
      payload: { id: fieldId, newValue: value },
    });
  };

  const handleCancelEdit = () => {
    setEditableUser(user);
    dispatchCurrentUser({ type: generalInfoActions.setUser, payload: user });
    !createMode && setIsEdit(!isEdit);
  };

  const handleSaveEdit = async () => {
    const user = { ...currentUser };
    await userApi.saveUser(user);
    if (userId) {
      loadUser(userId);
    }
    !createMode && setIsEdit(!isEdit);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '8px 8px 8px 0',
      }}
    >
      <ProfilePageHeader
        currentTab={currentTab}
        handleTabChange={handleTabChange}
        handleEditButtonClick={handleEditButtonClick}
      />
      <ProfileGeneralContent index={0} currentTab={currentTab}>
        <InfoBlock title="Общие сведения">
          <GeneralInfo
            user={currentUser}
            isEdit={isEdit}
            handleChange={handleEditInfoChange}
          />
        </InfoBlock>
        <InfoBlock
          title="Идентификаторы"
          expandable
          addButton={
            isEdit && (
              <Button
                startIcon={<IconPlus />}
                onClick={handleAddIdentifier}
                size="small"
                fullWidth={false}
              >
                Добавить
              </Button>
            )
          }
        >
          <Identifiers
            userIdentifiers={currentUser.accountIdentifierDTO}
            isEdit={isEdit}
            handleRemove={handleRemoveIdentifier}
            handleChange={handleChangeIdentifier}
          />
        </InfoBlock>
        <InfoBlock
          title="Контакты"
          expandable
          addButton={
            isEdit && (
              <Button
                startIcon={<IconPlus />}
                onClick={handleAddContact}
                size="small"
                fullWidth={false}
              >
                Добавить
              </Button>
            )
          }
        >
          <Contacts
            userContacts={currentUser.contactPointDTO}
            isEdit={isEdit}
            handleRemove={handleRemoveContact}
            handleChange={handleChangeContact}
          />
        </InfoBlock>
      </ProfileGeneralContent>
      {isEdit && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
            borderTop: '1px solid',
            borderTopColor: 'grey.400',
          }}
        >
          <Button
            variant="outlined"
            sx={{ width: '147px' }}
            onClick={handleCancelEdit}
          >
            Отмена
          </Button>
          <Button sx={{ width: '147px' }} onClick={handleSaveEdit}>
            Создать
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default ProfilePage;
