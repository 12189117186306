import { Box } from '@mui/material';
import NavigationBar from '@components/navigation-bar/navigation-bar';
import { Outlet } from 'react-router-dom';

const MainPage = () => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        overflowX: 'hidden',
        padding: '16px 20px',
      }}
    >
      <NavigationBar />
      <Box
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default MainPage;
