import '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';

export const brandPalette: PaletteOptions = {
  common: {
    white: '#fff',
    black: '#000',
  },
  primary: {
    xLight: '#A4CEFD',
    light: '#E6F3FF',
    main: '#0576FF',
    dark: '#0264DB',
  },
  error: {
    xLight: '#FDB6A6',
    light: '#FFECE8',
    main: '#F85542',
    dark: '#BC2F13',
  },
  warning: {
    xLight: '#FFE4CC',
    light: '#FFE4CC',
    main: '#F87342',
    dark: '#F87342',
  },
  success: {
    light: '#DFFCC2',
    xLight: '#DFFCC2',
    main: '#3DB45A',
    dark: '#2A8436',
  },
  yellow: {
    xLight: '#FFEECC',
    light: '#FFEECC',
    main: '#FEC84D',
    dark: '#FFB40A',
  },
  violet: {
    xLight: '#F1E8FC',
    light: '#F1E8FC',
    main: '#9747FF',
    dark: '#9747FF',
  },
  brown: {
    xLight: '#FCF3E3',
    light: '#FCF3E3',
    main: '#E3CBA6',
    dark: '#806A46',
  },
  grey: {
    50: '#F5F5F5',
    100: '#F5F5F5',
    200: '#F5F5F5',
    300: '#F5F5F5',
    400: '#F5F5F5', // light
    500: '#E4E5E7', // main
    600: '#7F838A', // dark
    700: '#3D4450', // xDark
    800: '#0C1524', // black
    900: '#0C1524',
    A100: '#0C1524',
    A200: '#0C1524',
    A400: '#0C1524',
    A700: '#0C1524',
  },
  text: {
    primary: '#151B24',
    secondary: '#151B24',
    disabled: '#151B24',
  },
  background: {
    default: '#F2F4F7',
    paper: '#fff',
  },
  extra: {
    hoverTable: '#F8F9FB',
    breakBorder: '#CED6E0',
    breakBorderLight: '#CED6E03D',
  },
};
