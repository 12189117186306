import { Components, Palette } from '@mui/material';
import { typography } from './typography';

export const themeComponents = (palette: Palette): Components => ({
  MuiTableCell: {
    defaultProps: {
      padding: 'none',
    },
    styleOverrides: {
      root: {
        flexDirection: 'row',
      },
    },
  },
  MuiTableSortLabel: {
    styleOverrides: {
      iconDirectionAsc: {
        transform: 'none',
      },
      iconDirectionDesc: {
        transform: 'none',
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      spacer: {
        flex: 'none',
      },
    },
  },
  MuiIconButton: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiInputLabel: {
    defaultProps: {
      shrink: true,
    },
    styleOverrides: {
      root: () => ({
        ...typography.captionTitle,
        color: palette.grey[800],
        transform: 'none',
        position: 'relative',
        paddingBottom: '8px',
        '&:not(.Mui-error).Mui-focused': {
          color: palette.grey[800],
        },
      }),
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      notched: false,
    },
    styleOverrides: {
      root: () => ({
        borderRadius: '8px',
        backgroundColor: palette.common.white,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey[500],
        },
        '&:not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline':
          {
            borderColor: palette.grey[500],
          },
        '&:not(.Mui-error):not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline':
          {
            borderColor: palette.grey[600],
          },
        '&.Mui-disabled': {
          backgroundColor: palette.grey[400],
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        input: {
          padding: '10px 12px',
        },
        fontSize: '16px',
        lineHeight: '24px',
      },
      sizeSmall: {
        input: {
          padding: '8px 12px',
        },
        fontSize: '14px',
        lineHeight: '19.6px',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        padding: '10px 12px',
      },
    },
  },
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        h1: 'h2',
        h2: 'h2',
        h3: 'h2',
        h4: 'h2',
        h5: 'h2',
        h6: 'h2',
        subtitle1: 'h2',
        subtitle2: 'h2',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableRipple: true,
      variant: 'contained',
      color: 'primary',
    },
    styleOverrides: {
      root: () => ({
        textTransform: 'none',
        ':disabled': {
          backgroundColor: palette.grey[400],
          color: palette.grey[500],
          borderColor: palette.grey[500],
          border: '1px solid',
        },
      }),
    },
    variants: [
      {
        props: { variant: 'outlined', color: 'primary' },
        style: () => ({
          color: palette.grey[800],
          borderColor: palette.grey[500],
          ':hover': {
            backgroundColor: palette.common.white,
            borderColor: palette.grey[600],
          },
        }),
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: () => ({
          color: palette.grey[800],
          ':hover': {
            backgroundColor: palette.grey[400],
          },
        }),
      },
      {
        props: { size: 'small' },
        style: {
          padding: '8px 15px',
          fontSize: '14px',
          lineHeight: '20px',
          borderRadius: 8,
        },
      },
      {
        props: { size: 'medium' },
        style: {
          padding: '10px 22px',
          fontSize: '16px',
          lineHeight: '24px',
          borderRadius: 8,
        },
      },
      {
        props: { size: 'large' },
        style: {
          padding: '16px 28px',
          fontSize: '18px',
          lineHeight: '24px',
          borderRadius: 12,
        },
      },
    ],
  },
});
