import { Box, Drawer, Typography } from '@mui/material';
import React from 'react';
import { navigationLinks } from '@mock-data/navigation-list';
import NavLink from './nav-link';
import logo from '../../assets/img/logo.png';

const drawerWidth = 240;

const NavigationBar = () => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          gap: '18px',
          boxSizing: 'border-box',
          padding: '26px 20px',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 14px',
          height: '44px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '44px',
          }}
        >
          <img src={logo} alt="Logo" />
        </Box>
        <Typography variant="captionTitle">PsHelp</Typography>
      </Box>
      <Box sx={{ gap: 4 }}>
        {navigationLinks.map(({ link, name, icon }) => (
          <NavLink to={link} icon={icon}>
            {name}
          </NavLink>
        ))}
      </Box>
    </Drawer>
  );
};

export default NavigationBar;
