import { InfoField } from '@components/info-field/info-field';
import { AccountStatus, EsiaType, UserType } from '@declarations/user';
import { Box, Grid } from '@mui/material';

type Props = {
  user: UserType;
  isEdit?: boolean;
  handleChange: ({
    value,
    fieldId,
  }: {
    value: string | number;
    fieldId: string;
  }) => void;
};

const GeneralInfo = ({ user, isEdit, handleChange }: Props) => {
  const esiaOptions: EsiaType[] = ['SIMPLE', 'STANDARD', 'CONFIRMED'];
  const accountStatusOptions: AccountStatus[] = [
    'PENDING',
    'ACTIVE',
    'BLOCKED',
    'RETIRED',
  ];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Grid
        container
        spacing={3}
        sx={{
          paddingBottom: '45px',
          borderBottom: '1px solid',
          borderBottomColor: 'grey.400',
        }}
      >
        {!isEdit && (
          <InfoField title="ID" fieldId="id" content={user?.id || '-'} />
        )}
        <InfoField
          title="Тип уч. записи в ЕСИА"
          content={user?.esiaTypeDTO || '-'}
          fieldId="esiaTypeDTO"
          isEdit={isEdit}
          xs={isEdit ? 6 : 4}
          options={esiaOptions}
          handleChange={handleChange}
        />
        <InfoField
          title="Статус учётной записи"
          content={user?.accountStatusDTO || '-'}
          fieldId="accountStatusDTO"
          isEdit={isEdit}
          xs={isEdit ? 6 : 4}
          options={accountStatusOptions}
          handleChange={handleChange}
        />
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{
          display: 'flex',
          paddingTop: '25px',
          paddingBottom: '45px',
        }}
      >
        <InfoField
          title="Фамилия"
          fieldId="nameFamily"
          content={user?.nameFamily || ''}
          isEdit={isEdit}
          handleChange={handleChange}
        />
        <InfoField
          title="Имя"
          fieldId="nameGiven"
          content={user?.nameGiven || ''}
          isEdit={isEdit}
          handleChange={handleChange}
        />
        <InfoField
          title="Отчество"
          fieldId="nameMiddle"
          content={user?.nameMiddle || ''}
          isEdit={isEdit}
          handleChange={handleChange}
        />
      </Grid>
    </Box>
  );
};

export default GeneralInfo;
