import React, { FC, ReactNode } from 'react';
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
  CssBaseline,
  Palette,
} from '@mui/material';
import { brandPalette } from './palette';
import { breakpoints } from './breakpoints';
import { typography } from './typography';
import { themeComponents } from './components';
import '../assets/styles/global.css';

const theme = createTheme({
  palette: brandPalette,
  breakpoints,
  typography,
  components: themeComponents(brandPalette as Palette),
});

const ThemeProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
