import { IconChevronDown } from '@components/icons';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardProps,
  Collapse,
  Typography,
  styled,
} from '@mui/material';
import React, { useState } from 'react';

interface StyledCardProps extends CardProps {
  expanded: boolean;
}

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) =>
    !['title', 'index', 'expanded'].includes(prop as string),
})<StyledCardProps>(({ expanded, theme }) => ({
  borderRadius: '9px',
  backgroundColor: theme.palette.extra.hoverTable,
  boxShadow: 'none',
  padding: '24px',
  '& .MuiCardHeader-root': {
    padding: expanded ? '0 0 24px 0' : 0,
    borderBottom: expanded ? '1px solid' : 'none',
    borderBottomColor: expanded ? theme.palette.grey[400] : 'none',
  },
  '& .MuiCardContent-root': {
    padding: expanded ? '24px 0 0 0' : 0,
    '&:last-child': {
      padding: !expanded && 0,
    },
  },
}));

interface Props {
  title: string;
  index: number;
  isEdit?: boolean;
  isExpanded?: boolean;
  children: React.ReactNode;
  deleteButton: React.ReactNode;
}

const ExpandableTitle = ({
  title,
  index,
  expanded,
  onClick,
}: {
  title: string;
  index: number;
  expanded: boolean;
  onClick: () => void;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
      }}
    >
      <Typography>
        {title} #{index}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.15s',
        }}
      >
        <IconChevronDown />
      </Box>
    </Box>
  );
};

const InfoExpandableChunk = ({
  title,
  index,
  children,
  isEdit,
  deleteButton,
  isExpanded = false,
}: Props) => {
  const [expanded, setExpanded] = useState<boolean>(isExpanded);

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledCard expanded={expanded}>
      <CardHeader
        sx={{}}
        title={
          <Box display="flex" justifyContent="space-between">
            <ExpandableTitle
              title={title}
              index={index}
              onClick={handleExpanded}
              expanded={expanded}
            />
            {isEdit && deleteButton}
          </Box>
        }
      />
      <CardContent
        sx={{
          borderTop: expanded ? '1px solid' : '',
          borderTopColor: expanded ? 'extra.breakBorderLight' : '',
        }}
      >
        <Collapse in={expanded} timeout={0}>
          {children}
        </Collapse>
      </CardContent>
    </StyledCard>
  );
};

export default InfoExpandableChunk;
